import moment from "moment";
import queryString from "query-string";
import { getToken, ten_years_ago, today } from "utils";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "localhost:3000";

const _stringify: any = (value: any) => {
  return encodeURIComponent(JSON.stringify(value));
};
const get_pages = (state: any) => {
  let {
    year,
    month_quarter,
    totalRegisterUsers: totalRegisterUsers,
    overallUserRating: overallUserRating,
    webinars: webinars,
    top3Topics: top3Topics,
    topUserOutcomes: topUserOutcomes,
    numberCompletedDass: numberCompletedDass
  } = state;

  const query = {
    access_token: getToken(),
    month: `${month_quarter}`,
    year: `${year}`
   
  };
  const query_1 = {
    access_token: getToken(),
    month: `${month_quarter}`,
    year: `${year}`,
    totalRegisterUsers: totalRegisterUsers,
    overallUserRating: overallUserRating
  };

  // 1
  const path_1 = `${BASE_URL}/aia-overview-pdf`;
  
  const url_1 = queryString.stringifyUrl({ url: path_1, query: query_1 });

  const query_2 = {
    access_token: getToken(),
    month: `${month_quarter}`,
    year: `${year}`,
    webinars: _stringify(webinars),
    top3Topics: _stringify(top3Topics)
  };
    // 2
  const path_2 = `${BASE_URL}/aia-behavioural-insights-pdf`;
  
    const url_2 = queryString.stringifyUrl({ url: path_2, query: query_2 });

  
      // 3
      const path_3 = `${BASE_URL}/aia-top-concert-chart-pdf`;
  
      const url_3 = queryString.stringifyUrl({ url: path_3, query: query });
    
      const path_4 = `${BASE_URL}/aia-breakdown-gender-chart-pdf`;
  
      const url_4 = queryString.stringifyUrl({ url: path_4, query: query });

      const path_5 = `${BASE_URL}/aia-breakdown-age-chart-pdf`;
  
      const url_5 = queryString.stringifyUrl({ url: path_5, query: query });

      const query_6 = {
        access_token: getToken(),
        month: `${month_quarter}`,
        year: `${year}`,
        topUserOutcomes: _stringify(topUserOutcomes)
       
      };

      const path_6 = `${BASE_URL}/aia-top-user-outcomes-pdf`;
  
      const url_6 = queryString.stringifyUrl({ url: path_6, query: query_6 });

      const query_7 = {
        access_token: getToken(),
        month: `${month_quarter}`,
        year: `${year}`,
        numberCompletedDass: _stringify(numberCompletedDass)
       
      };
      const path_7 = `${BASE_URL}/aia-health-outcomes-chart-pdf`;
  
      const url_7 = queryString.stringifyUrl({ url: path_7, query: query_7 });
  
      const path_8 = `${BASE_URL}/aia-avg-dass-scores-chart-pdf`;
  
      const url_8 = queryString.stringifyUrl({ url: path_8, query: query });

      const path_9 = `${BASE_URL}/aia-interpretation-dass-scores-table`;
  
      const url_9 = queryString.stringifyUrl({ url: path_9, query: query });
  
      const path_10 = `${BASE_URL}/aia-metrics-definition`;
  
      const url_10 = queryString.stringifyUrl({ url: path_10, query: query });
  
  
  const pages = [url_1, url_2, url_3, url_4, url_5, url_6, url_7, url_8, url_9, url_10]

  return pages;
};

export default get_pages;
