import SectionLabel from 'components/Section'
import DepartmentHeath from "containers/Overview/Components/DepartmentHeath";
import { useLocation } from "react-router-dom";

const DepartmentHeathPdf = () => {

  const location = useLocation();
  const decode_and_parse: any = (key: string, defaultValue: string) => {
    const value = new URLSearchParams(location.search).get(key) || defaultValue;
    return JSON.parse(decodeURIComponent(value))
  }
  
  const departmental_health_overview = decode_and_parse("departmental_health_overview", "{}");
  const is_dependent = new URLSearchParams(location.search).get('is_dependent') == 'true';
  const program = decode_and_parse("program", "{}");
  const video_therapy_session_count = new URLSearchParams(location.search).get('video_therapy_session_count') || "0"

  return (
        // myiFrame id is needed to be able to print to the file (can refer to the lambda function)

    <>
      <main id="myiFrame">
          {
            !is_dependent && (
              <>
                 <div className="CardVisualization mx-auto mt-10 px-4">
            <SectionLabel
              tooltip={"Note: Only departments with more than 10 active users will be displayed"}
              key={'SectionLabel-3'} title="Departmental Health" />
            <div className="flex flex-wrap justify-between space-x-8 mb-10">
              <DepartmentHeath departmental_health_overview={departmental_health_overview} />
            </div>
          </div>
          <div className=" mx-auto px-4 mt-10 flex">
            <div className="w-1/2 ">
              <SectionLabel key={'SectionLabel-3'} title="Individual Therapy/ Counselling Sessions Utilisation" />
              <div className="flex flex-wrap justify-between space-x-8 ">
                <div className={"bg-white shadow sm:rounded-lg flex-1"}>
                  <div className="px-4 py-5 sm:p-6 bg-white">

                    <div className="mt-2 font-bold max-w-xl text-3xl text-primary">
                      <p>{video_therapy_session_count || video_therapy_session_count == "0" ? video_therapy_session_count : "-"}
                        <span style={{ color: "#59817D" }} className="text-sm font-normal ml-2">
                          sessions were utilised
                        </span></p>

                    </div>
                  </div>

                </div >
              </div>
            </div>
            <div className="w-1/2  ml-6 pb-8">
              <SectionLabel key={'SectionLabel-3'} title="24/7 Wellness Hotline & Crisis Intervention Utilisation" />
              <div className="flex flex-wrap justify-between space-x-8 ">
                <div className={"bg-white shadow sm:rounded-lg flex-1"}>
                  <div className="px-4 py-5 sm:p-6 bg-white">

                    <div className="mt-2 font-bold max-w-xl text-3xl text-primary">
                      <p>{program?.level_3_report || program?.level_3_report == 0 ? program?.level_3_report : "-"}
                        <span style={{ color: "#59817D" }} className="text-sm font-normal ml-2">
                          calls were made
                        </span></p>

                    </div>
                  </div>

                </div >
              </div>
            </div>
          </div>
              </>
            )
          }
      </main>
    </>
  )
}

export default DepartmentHeathPdf;