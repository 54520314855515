
import { useHistory } from "react-router-dom";
import moment from 'moment'
import { formatDate } from "utils";


type ListProgramsTableProps = {
    data: any
}

const ListProgramsTableUserDemo = (props: ListProgramsTableProps) => {
    const history = useHistory();
    const handleActiPrItem = (id: string | number) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        history.push(`/activation-program/${id}`)
    }
    return (

        <div className="w-full mx-auto mt-6">
            <div className="flex flex-wrap justify-between space-x-8  ">
                <div className="w-full flex flex-col">
                    <div className="w-full -my-2 overflow-x-auto">
                        <div className="w-full py-2 align-middle inline-block ">
                            <div className="w-full shadow">
                                <table
                                    className=" divide-y divide-grey1 w-full">
                                    <thead className="bg-white">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Title of program
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Target participants
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props?.data?.map((item: any, personIdx: number) => (
                                            <tr
                                                onClick={() => handleActiPrItem(item.id)}
                                                key={item.id}
                                                className={`${personIdx % 2 === 0 ? 'bg-white' : 'bg-blueLight'} hover:bg-[#e6fcf9] cursor-pointer`}>
                                                <td className="px-6 py-4   text-sm font-medium text-gray-900">{formatDate(item.begin_at)}</td>
                                                <td className="px-6 py-4   text-sm text-gray-500">{item.title}</td>
                                                <td className="px-6 py-4   text-sm text-gray-500">{item.target}</td>

                                            </tr>

                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default ListProgramsTableUserDemo

