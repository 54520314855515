import SectionLabel from 'components/Section'
import { isEmpty } from 'lodash';
import OverViewCard1 from "containers/Overview/Components/OverViewCard1";
import OverViewCard2 from "containers/Overview/Components/OverViewCard2";
import { useLocation } from "react-router-dom";
import { ten_years_ago, today } from 'utils';
import queryString from "query-string";
import moment from 'moment';

const Overview = () => {


  const location = useLocation();
  const decode_and_parse: any = (key: string, defaultValue: string) => {
    const value = new URLSearchParams(location.search).get(key) || defaultValue;
    return JSON.parse(decodeURIComponent(value))
  }
  const program = decode_and_parse("program", "{}");
  const org = decode_and_parse("org", "{}");
  const app = decode_and_parse("app", "{}");
  const webinar_summary = decode_and_parse("webinar_summary", "[]");
  const is_dependent = new URLSearchParams(location.search).get('is_dependent') == 'true';

  const params: any = queryString.parse(location.search);
  let { startDate, endDate } =
    params || {};
  let start_date = startDate ? startDate : null;

  let end_date = endDate ? endDate : null;

  const webinarsView = webinar_summary && !isEmpty(webinar_summary.type) && webinar_summary.type.map((item: any, index: number) => {
    for (const [key, value] of Object.entries(item)) {
      return <OverViewCard1
        key={'OverViewCard1-' + index}
        className={'bg-white shadow sm:rounded-lg flex-1'}
        title={key} value={value || 0}
        description={"Webinars"}
        colorTitle={"#004876"}
        bgDescription={"rgba(234, 250, 247, 0.4)"}
      />
    }
    return null

  })

  const dataOverview_1 =
    [
      {
        key: 'OverViewCard1-2',
        className: 'bg-white shadow sm:rounded-lg flex-1',
        title: '1ST LEVEL OF SUPPORT',
        value: program?.level_1 || 0,
        unit: "headcount",
        subtitle: "Preventive Care​",
        description: 'ThoughtFullChat App Coaching',
        listSubDescription: ["Unlimited text & audio messages​",
          "Asynchronous response within 24 hours Mon-Fri"],
        bgDescription: "rgba(234, 250, 247, 0.5)",
        colorTitle: "#004876",
        fontWeight: 600
      },
      {
        key: 'OverViewCard1-3',
        className: 'bg-white shadow sm:rounded-lg flex-1',
        title: '2ND LEVEL OF SUPPORT',
        value: program?.level_2 || 0,
        unit: "sessions",
        subtitle: "Curative Care​",
        description: 'Individual Therapy/ Counselling Sessions',
        listSubDescription: ["60 minute video therapy sessions"],
        bgDescription: "rgba(250, 249, 234, 0.5)",
        colorTitle: "#004876",
        fontWeight: 600
      },
      {
        key: 'OverViewCard1-4',
        className: 'bg-white shadow sm:rounded-lg flex-1',
        title: '3RD LEVEL OF SUPPORT',
        subtitle: "Crisis Intervention",
        value: program?.level_3 || 0,
        unit: "calls",
        description: '24/7 Wellness Hotline & Crisis Intervention',
        listSubDescription: ["Telephone psychological first aid and triaging"],
        bgDescription: "rgba(250, 234, 234, 0.5)",
        colorTitle: "#004876",
        fontWeight: 600
      }
    ]
  const utilisation = [
    // {
    //   key: "OverViewCard2-1",
    //   percent: true,
    //   className: 'bg-white shadow sm:rounded-lg flex-1',
    //   value: app?.first_time_engaging_with_mental_health,
    //   description: 'First time engaging with mental health services',
    // },
    {
      total_active_user: app?.total_active_user,
      key: 'OverViewCard2-2',
      percent: true,
      className: 'bg-white shadow sm:rounded-lg flex-1',
      value: app?.app_rate,
      description: 'App Utilisation Rate Across the Organisation',
    },
    {
      total_active_coachees: app?.total_active_coachees,
      key: 'OverViewCard2-3',
      percent: true,
      className: 'bg-white shadow sm:rounded-lg flex-1',
      value: app?.engaging_with_mental_health_course,
      description: 'Are engaging with mental health coaches',
    },
    {
      total_active_coachees: app?.total_active_coachees,
      key: 'OverViewCard2-3',
      percent: true,
      className: 'bg-white shadow sm:rounded-lg flex-1',
      value: app?.self_serve_users_percent,
      description: 'have engaged with a self-awareness tool',
    }
  ]

  return (
    // myiFrame id is needed to be able to print to the file (can refer to the lambda function)

    <>
      <main id="myiFrame">
        <div className="CardVisualization py-6 bg-grey min-h-screen px-4">
          <div className=" mx-auto px-1 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-blue">Overview of {org?.name}'s Mental Wellbeing Program</h1>
            {start_date && end_date && (
              <>
                <br />
                <p
                  style={{ color: "rgb(89, 129, 125)" }}
                  className="text-sm text-blue">Exported date range: {moment(start_date).format("DD MMM YYYY")} - {moment(end_date).format("DD MMM YYYY")}</p>

              </>

            )}
            {
              !is_dependent && (
                <>
                  <div className="mt-5">
                    <SectionLabel key={'SectionLabel-1'} title={`Total Available Support Coverage`} />

                  </div>
                  <div className="flex flex-wrap justify-between space-x-8 ">
                    {
                      dataOverview_1.map(data => (
                        <OverViewCard1
                          key={data.key}
                          className={data.className}
                          title={data.title}
                          value={data.value}
                          unit={data.unit}
                          subtitle={data.subtitle}
                          description={data.description}
                          listSubDescription={data.listSubDescription}
                          bgDescription={data.bgDescription}
                          colorTitle={data.colorTitle}
                          fontWeight={data.fontWeight}
                        />
                      ))
                    }
                  </div>
                </>
              )
            }

          </div>
          {
            !is_dependent && (
              <>
                <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10">
                  <SectionLabel key={'SectionLabel-2'} title={`Total Available Activation Programs`} />
                  <div className="flex flex-wrap justify-between space-x-8 ">
                    {webinarsView}
                  </div>
                </div>
              </>
            )
          }

          <div className=" mx-auto px-1 sm:px-6 md:px-8 mt-10">
            <SectionLabel key={'SectionLabel-3'} title="ThoughtFullChat App Utilisation" />
            <div className="flex flex-wrap justify-between space-x-8 ">
              {utilisation.map(data => (
                <OverViewCard2
                  key={data.key}
                  percent={data.percent}
                  className={data.className}
                  value={data.value}
                  description={data.description}
                  total_active_user={data.total_active_user}
                  total_active_coachees={data.total_active_coachees}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Overview